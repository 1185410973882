import { useCallback, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import useMemoSelector from 'hooks/useMemoSelector';

import sortByTranslation from 'utils/sortByTranslation';

import { deviceParams, getAdStore } from 'store/reselect';

import useStyles from './styles';

const ListWrapper = ({
  isMobile,
  columnsKey,
  toggleCollapse,
  mobileOpenedKey,
  categoriesList,
}) => {
  const t = useTranslations();

  const currentCategory = categoriesList.find(e => e.value === columnsKey);

  const subList = sortByTranslation(
    { data: currentCategory?.labels, key: 'name' },
    t,
  ).map(link => (
    <li key={link.id}>
      <Link href={`/${currentCategory?.slug}/${link.slug}`}>
        <a>
          <span>{link.t_name}</span>
        </a>
      </Link>
    </li>
  ));

  return currentCategory ? (
    <ul>
      <li
        className={`like-title-link ${
          isMobile && mobileOpenedKey === columnsKey
            ? 'like-title-link--collapse-show'
            : ''
        }`}
      >
        <Link href={`/${currentCategory?.slug}`}>
          <a>
            <span>{t(currentCategory.value)}</span>
          </a>
        </Link>
        {isMobile && currentCategory.labels?.length ? (
          <span
            className="icon-Chevron---Right"
            onClick={() => toggleCollapse(columnsKey)}
          ></span>
        ) : (
          ''
        )}
      </li>
      {isMobile && currentCategory.labels?.length ? (
        <Collapse isOpen={mobileOpenedKey === columnsKey}>{subList}</Collapse>
      ) : (
        subList
      )}
    </ul>
  ) : (
    ''
  );
};

const DiscoverLinks = ({ columns }) => {
  const { deviceType } = useSelector(deviceParams());
  const { categoriesWithSections: { categories = {} } = {} } =
    useMemoSelector(getAdStore);

  const mutatedData = Object.values(categories).reduce((acc, el) => {
    acc.push({
      value: el.name,
      labels: el.sections,
      pathname: '/',
      id: el.id,
      slug: el.slug,
    });

    return acc;
  }, []);

  const discoveryLinks = useRef();
  const getCurrentDeviceColumns = columns[deviceType] || [];
  const styles = useStyles({
    width: `${100 / getCurrentDeviceColumns.length}%`,
  });
  const [mobileCollapseKey, setMobileCollapseKey] = useState(null);

  const toggleCollapse = useCallback(
    key => {
      setMobileCollapseKey(mobileCollapseKey === key ? null : key);
    },
    [mobileCollapseKey],
  );

  return (
    <div
      className={`${styles['container-of-link-list']} ${
        deviceType === 'mobile' ? 'container-of-link-list__mobile' : ''
      }`}
      ref={discoveryLinks}
    >
      {getCurrentDeviceColumns.map(list => (
        <div className={styles['column--block']} key={list}>
          {list.map(cat => (
            <ListWrapper
              columnsKey={cat}
              categoriesList={mutatedData}
              key={cat}
              isMobile={deviceType === 'mobile'}
              toggleCollapse={toggleCollapse}
              mobileOpenedKey={mobileCollapseKey}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
DiscoverLinks.propTypes = {
  columns: PropTypes.object.isRequired,
};
export default memo(DiscoverLinks);
