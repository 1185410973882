import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IfInViewPort from 'hoc/RenderIfInView';
import { useTranslations } from 'next-intl';

import { MainAd } from 'Components/Smart/Tiles';
import MySJSimpleTitleBar from 'Components/Dumb/MySJSimpleTitleBar';
import SJSlider from 'Components/Plugins/Sliders/SJSlider';

import { deviceParams } from 'store/reselect';

import useStyles from './styles';

const PopularCategories = props => {
  const t = useTranslations();
  const { deviceType } = useSelector(deviceParams());
  const popularCategories = props.data || [];

  const classes = useStyles();

  return (
    <div
      style={{ ...props.style }}
      className={classes['main-wrapper_pop-category']}
    >
      <MySJSimpleTitleBar>
        <div className="simple-bar_title" data-intro="secondary-bar">
          <h4 className="title-content">{t('homepage_popular_title')}</h4>
        </div>
      </MySJSimpleTitleBar>
      <IfInViewPort placeholderHeight={deviceType === 'mobile' ? 714 : 276}>
        <div className={classes['popular-categories-container']}>
          {deviceType === 'mobile' ? (
            popularCategories.map(category => (
              <MainAd
                key={category.id}
                data={category}
                type="popular-category"
                isMobile
              />
            ))
          ) : (
            <SJSlider
              autoPlay={false}
              itemMargin={35}
              containerPadding={'20px 20px 10px'}
              column={{
                xsss: 1,
                xss: 1,
                xs: 2,
                sm: 3,
                md: 2,
                lg: 3,
                xl: 5,
              }}
              nextPrevBtns
            >
              {popularCategories.map(category => (
                <MainAd
                  key={category.category_id}
                  data={category}
                  imgWrapper={{ h: 182 }}
                  type="popular-category"
                />
              ))}
            </SJSlider>
          )}
        </div>
      </IfInViewPort>
    </div>
  );
};
PopularCategories.defaultProps = { popularCategories: [], style: {} };
PopularCategories.propTypes = {
  style: PropTypes.object,
  data: PropTypes.array.isRequired,
};
export default memo(PopularCategories);
