import React, { useRef } from 'react';

const RenderIfInView = ({ children, placeholderHeight }) => {
  const refContainer = useRef();

  return (
    <div style={{ minHeight: placeholderHeight }} ref={refContainer}>
      {children}
    </div>
  );
};
export default RenderIfInView;
