import { makeStyles } from '@material-ui/core/styles';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'container-of-link-list': {
    display: 'flex',
    '&.container-of-link-list__mobile': {},
  },
  'column--block': {
    width: props => props.width,
    '& ul': {
      listStyleType: 'none',
      padding: '0',
      marginBottom: '35px',
      paddingRight: '10px',
      ...MediaQuery.down({
        paddingRight: '0px',
        marginBottom: '10px',
      }).sm,
      '& li': {
        cursor: 'pointer',
        marginBottom: '10px',
        '&:last-child': {
          marginBottom: '0px',
        },
      },
      '& span': {
        color: '#fff',
        fontSize: '13px',
        display: 'block',
      },
      '& .like-title-link': {
        paddingBottom: '10px',
        marginBottom: '0px',
        position: 'relative',
        '& .icon-Chevron---Right': {
          position: 'absolute',
          right: '0',
          transition: '.4s',
          top: '-5px',
          fontSize: '13px',
          height: '32px',
          width: '32px',
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: variables.$iconColor,
          cursor: 'pointer',
        },
        '& span': {
          fontSize: '16px',
          cursor: 'pointer',
          fontWeight: 'bold',
        },
        '&.like-title-link--collapse-show': {
          '& .icon-Chevron---Right': {
            transform: 'rotate(-90deg)',
          },
        },
      },
    },
  },
});
