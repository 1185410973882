const getDeviceType = ctx => {
  const userAgent = ctx?.req
    ? ctx.req.headers['user-agent']
    : navigator.userAgent;

  const isIOS = /iPad|iPhone|iPod/.test(userAgent);

  const isAndroid = /android/i.test(userAgent);

  const isWindowsPhone = /windows phone/i.test(userAgent);

  const isMobile =
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  return {
    isIOS,
    isMobile,
    isAndroid,
    isWindowsPhone,
  };
};

export default getDeviceType;
