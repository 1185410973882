import { memo } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslations } from 'next-intl';

import safeTranslate from 'utils/safeTranslate';

import useStyles from './styles';

const Information = () => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className={styles['information-wrapper']}>
      <Row>
        <Col xs={12} sm={4}>
          <div className="info-item">
            <h4>
              <span className="icon-Touch"></span>
              {t('homepage_info_title_1')}
            </h4>
            <p>{safeTranslate('homepage_info_text_1')}</p>
          </div>
        </Col>
        <Col xs={12} sm={4}>
          <div className="info-item">
            <h4>
              <span className="icon-Visitors"></span>
              {t('homepage_info_title_2')}
            </h4>
            <p>{safeTranslate('homepage_info_text_2')}</p>
          </div>
        </Col>
        <Col xs={12} sm={4}>
          <div className="info-item">
            <h4>
              <span className="icon-Advertenties"></span>
              {t('homepage_info_title_3')}
            </h4>
            <p>{safeTranslate('homepage_info_text_3')}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default memo(Information);
