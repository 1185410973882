import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import useDevice from 'hooks/use-media-device';
import IfInViewPort from 'hoc/RenderIfInView';
import { useTranslations } from 'next-intl';

import { Intro } from 'Components/Plugins';
import Banner from 'Components/Banner';
import Breadcrumbs from 'Components/Dumb/Breadcrumbs';
import AsideMenu from 'Components/Dumb/AsideMenu';
import Suggested from 'Components/Home/Suggested';
import Information from 'Components/Home/Information';
import DiscoverLinks from 'Components/Home/DiscoverLinks';
import PopularCategories from 'Components/Home/PopularCategories';
import MySJSimpleTitleBar from 'Components/Dumb/MySJSimpleTitleBar';
import Canonical from 'Components/Canonical';

import getDeviceType from 'utils/isMobile';
import { getRouterBasePath } from 'utils/meta';
import { saveToStorage, getFromStorage } from 'utils/persist';

import API from 'services/api';

// TODO::: Need to remove local logic and use one of these packages https://www.chameleon.io/blog/react-product-tour
const introSteps = [
  {
    element: 'account-icon',
    title: 'Your new My Sexjobs menu',
    description:
      'Welcome to your new My Sexjobs environment. This is the menu to navigate through My Sexjobs.',
    next: 'notification-icon',
    pointer: {
      height: 60,
      width: 60,
    },
  },
  {
    element: 'notification-icon',
    title: 'Introducing notifications',
    description:
      'We added notifications. Here you can find important information updates about your advertisements and account.',
    next: 'secondary-bar',
    pointer: {
      height: 60,
      width: 60,
    },
  },
  {
    element: 'secondary-bar',
    title: 'Secondary Bar',
    description:
      'We added notifications. Here you can find important information updates about your advertisements and account.!',
    radiusPointer: '50px',
    next: 'highlighted-bar',
  },
  {
    element: 'highlighted-bar',
    title: 'Highlighted Bar',
    description:
      'We added notifications. Here you can find important information updates about your advertisements and account.!',
    radiusPointer: '50px',
    next: 'most-viewed',
  },
  {
    element: 'most-viewed',
    title: 'Most Viewed',
    description:
      'We added notifications. Here you can find important information updates about your advertisements and account.!',
    radiusPointer: '50px',
  },
];

const discoverLinksColumns = {
  desktop: [
    ['ladies_of_pleasure', 'gay-bi'],
    ['events', 'vacancies', 'cam_phonesex', 'erotic-vacancies', 'shemale'],
    ['gangbang', 'men', 'sex_items'],
    ['erotic_massage', 'payment_in_kind', 'misc'],
    ['sm', 'free_sex_contact'],
  ],
  tablet: [
    ['ladies_of_pleasure', 'gay-bi', 'cam_phonesex', 'sm'],
    [
      'events',
      'vacancies',
      'erotic-vacancies',
      'shemale',
      'men',
      'sex_items',
      'gangbang',
    ],
    ['erotic_massage', 'payment_in_kind', 'misc', 'free_sex_contact'],
  ],
  mobile: [
    [
      'ladies_of_pleasure',
      'gay-bi',
      'cam_phonesex',
      'sm',
      'events',
      'vacancies',
      'erotic-vacancies',
      'shemale',
      'men',
      'sex_items',
      'gangbang',
      'erotic_massage',
      'payment_in_kind',
      'misc',
      'free_sex_contact',
    ],
  ],
};

const Home = ({
  isMobile,
  available,
  mostViewed,
  highlightedEvents,
  popularCategories,
  highlightedVacancies,
}) => {
  const t = useTranslations();
  const { deviceType } = useDevice();
  const [isIntroShow, setIsIntroShow] = useState(false);

  const { result, count } = available;
  const { result: mostViewedResult } = mostViewed;
  const { result: highlightedEventsResult } = highlightedEvents;
  const { result: highlightedVacanciesResult } = highlightedVacancies;

  const finishIntro = () => {
    saveToStorage('intro', true);
    setIsIntroShow(false);
  };

  useEffect(() => {
    const getIntro = getFromStorage('intro');
    if (!getIntro) setIsIntroShow(true);
  }, []);

  const { title, description } = getRouterBasePath('/');

  return (
    <>
      <Canonical />
      <NextSeo title={title} description={description} />
      {isIntroShow && (
        <Intro
          finishTour={finishIntro}
          somethingWentWrong={v => setIsIntroShow(v)}
          popupPlacement="bottom"
          flexible
          steps={introSteps}
        />
      )}
      <Container>
        <Row>
          <Col xs={12}>
            <IfInViewPort placeholderHeight={35}>
              <Breadcrumbs roadMap="home" />
            </IfInViewPort>
          </Col>
          {(deviceType === 'desktop' || !isMobile) && <AsideMenu />}
          <Col xs={12} md={8} lg={9} xl={10}>
            {/* Popular Categories */}
            <PopularCategories data={popularCategories} />

            {/*  */}
            {/* Available now */}
            <Suggested minHeight={328} type="general" data={result}>
              <MySJSimpleTitleBar style={{ marginTop: '16px' }}>
                <div className="simple-bar_title">
                  <div className="simple-bar-flexible--block">
                    <span className="title-content">
                      {t('homepage_available_title')}
                    </span>
                    <p className="simple-bar-flexible-info--block">
                      <span className="simple-bar-flexible-info--block-divider">
                        |
                      </span>
                      <span className="icon-Advertenties" />
                      {count}
                    </p>
                  </div>
                </div>
              </MySJSimpleTitleBar>
            </Suggested>

            <Link href="/nu-beschikbaar">
              <a>
                <span className="global-pages-links">
                  {t('homepage_available_show_more_text')}
                </span>
              </a>
            </Link>

            <Banner size={deviceType === 'desktop' ? '970x90' : '300x100'} />

            {/* Events */}
            <Suggested
              minHeight={376}
              type="events"
              data={highlightedEventsResult}
            >
              <MySJSimpleTitleBar style={{ marginTop: '16px' }}>
                <div className="simple-bar_title" data-intro="highlighted-bar">
                  <div className="simple-bar-flexible--block">
                    <span className="title-content">
                      {t('homepage_highlighted_events_title')}
                    </span>
                    <p className="simple-bar-flexible-info--block">
                      <span className="simple-bar-flexible-info--block-divider">
                        |
                      </span>
                      <span className="icon-Advertenties" />
                      {highlightedEvents.count}
                    </p>
                  </div>
                </div>
              </MySJSimpleTitleBar>
            </Suggested>
            <Link href="/events">
              <a>
                <span className="global-pages-links">
                  {t('homepage_highlighted_events_show_more_text')}
                </span>
              </a>
            </Link>

            {/* Most viewed */}
            <Suggested minHeight={330} type="general" data={mostViewedResult}>
              <MySJSimpleTitleBar style={{ marginTop: '16px' }}>
                <div className="simple-bar_title" data-intro="most-viewed">
                  <h4 className="title-content">
                    {t('homepage_most_viewed_title')}
                  </h4>
                </div>
              </MySJSimpleTitleBar>
            </Suggested>

            {/* Highlighted Vacancies */}
            <Suggested
              minHeight={189}
              type="vacancy"
              data={highlightedVacanciesResult}
            >
              <MySJSimpleTitleBar style={{ marginTop: '16px' }}>
                <div className="simple-bar_title">
                  <div className="simple-bar-flexible--block">
                    <span className="title-content">
                      {t('homepage_highlighted_vacancies_title')}
                    </span>
                    <p className="simple-bar-flexible-info--block">
                      <span className="simple-bar-flexible-info--block-divider">
                        |
                      </span>
                      <span className="icon-Advertenties" />
                      {highlightedVacancies.count}
                    </p>
                  </div>
                </div>
              </MySJSimpleTitleBar>
            </Suggested>
            <Link href="/erotische-vacatures">
              <a>
                <span className="global-pages-links">
                  {t('homepage_highlighted_vacancies_show_more_text')}
                </span>
              </a>
            </Link>

            <Banner size={deviceType === 'desktop' ? '970x90' : '300x100'} />

            {/* Information */}
            <IfInViewPort placeholderHeight={'auto'}>
              <Information />
            </IfInViewPort>

            <IfInViewPort placeholderHeight={'auto'}>
              <MySJSimpleTitleBar>
                {isMobile ? (
                  <h4
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: 'rgb(255, 255, 255)',
                      padding: '10px 16px 5px 16px',
                    }}
                  >
                    {t('homepage_footer_title')}
                  </h4>
                ) : (
                  <div className="simple-bar_title">
                    <h4 className="title-content">
                      {t('homepage_footer_title')}
                    </h4>
                  </div>
                )}
              </MySJSimpleTitleBar>
              <DiscoverLinks columns={discoverLinksColumns} />
            </IfInViewPort>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Home.getInitialProps = async props => {
  try {
    const { data: available } = await API.fetchAvailableAds(0, 10);
    const { data: highlightedEvents } = await API.fetchHighlightedEvents();
    const { data: mostViewed } = await API.fetchMostViewed();
    const { data: highlightedVacancies } =
      await API.fetchHighlightedVacancies();

    const { data: popularCategories } = await API.fetchPopularCategories();

    const { isMobile } = getDeviceType(props.ctx);

    return {
      isMobile,
      available,
      mostViewed,
      highlightedEvents,
      popularCategories,
      highlightedVacancies,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
export default Home;
