import { memo } from 'react';
import PropTypes from 'prop-types';
import IfInViewPort from 'hoc/RenderIfInView';

import SJSlider from 'Components/Plugins/Sliders/SJSlider';
import {
  MainAd,
  EventTile_General,
  Vacancy_General,
} from 'Components/Smart/Tiles';

import useStyles from './styles';

const Suggested = props => {
  const suggestedData = props.data;

  const styles = useStyles({
    minHeight: `${props.minHeight}px` || 'auto',
  });

  return (
    <div
      style={{ ...props.style }}
      className={styles['main-suggested-wrapper']}
    >
      {props.children}
      <IfInViewPort placeholderHeight={props.minHeight || 'auto'}>
        <div className={styles['suggested-container']}>
          <SJSlider
            autoPlay={false}
            interval={2000}
            itemMargin={20}
            mobileHalfPart={576}
            column={{
              xsss: 1,
              xss: 1,
              xs: 2,
              sm: 3,
              md: 3,
              lg: 2,
              xl: 5,
            }}
            nextPrevBtns={suggestedData?.length > 5}
          >
            {suggestedData?.map(item =>
              props.type === 'events' ? (
                <EventTile_General data={item} key={item.reference_id} />
              ) : props.type === 'general' ? (
                <MainAd
                  imgWrapper={{ h: 193 }}
                  key={item.reference_id}
                  data={item}
                  type="ad-tile"
                />
              ) : props.type === 'vacancy' ? (
                <Vacancy_General data={item} key={item.reference_id} />
              ) : (
                ''
              ),
            )}
          </SJSlider>
        </div>
      </IfInViewPort>
    </div>
  );
};
Suggested.defaultProps = {
  style: {},
};
Suggested.propTypes = {
  style: PropTypes.object,
  // link: PropTypes.string,
  // data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
};

export default memo(Suggested);
