import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'main-suggested-wrapper': {
    marginBottom: '6px',
  },
  'suggested-container': {
    minHeight: props => props.minHeight,
  },
});
