import { makeStyles } from '@material-ui/core/styles';
import {
  Shadow,
  TextEllipsisLineClamp,
} from 'static/styles/jss/abstracts/mixins';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'event--block': {
    cursor: 'pointer',
    backgroundColor: variables.$filters.$headerBG,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    borderRadius: '0 0 3px 3px',
    marginBottom: '16px',
    '& .image-wrapper': {
      position: 'relative',
      '& .cover-photo': {
        borderRadius: '3px 3px 0 0',
      },
      '& .promotion-icon': {
        zIndex: 2,
        position: 'absolute',
        width: '20px',
        height: '20px',
        top: '0',
        display: 'flex',
      },
    },
  },
  'details--block': {
    position: 'relative',
    padding: '10px',
    '& .time-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: '5px',
      '& > p': {
        margin: '0 10px 0 0',
        fontSize: '13px',
        color: variables.$greyTxtColor,
        fontWeight: '600',
        whiteSpace: 'nowrap',
      },
      '& .sticker__tile': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    '& .title-content': {
      ...TextEllipsisLineClamp(14, 2, 32, '18px'),
      display: 'inherit',
      whiteSpace: 'nowrap',
    },
  },
  'details--block-section__bt': {
    borderTop: `1px solid ${variables.$dividerColor}`,
    paddingTop: '10px',
    '& p': {
      margin: '0 0 10px',
      color: variables.$greyTxtColor,
      fontSize: '13px',
      '& span': {
        marginRight: '6px',
      },
    },
  },
});
