export default ({ data, key }, t) => {
  const outputKey = `t_${key}`;
  if (!data) return [];

  const translatedData = data.map(item => {
    if (!key) {
      return t(item);
    } else {
      return { ...item, [outputKey]: t(item[key]) };
    }
  });

  return translatedData.sort((a, b) => {
    const aValue = key ? a[outputKey] : a;
    const bValue = key ? b[outputKey] : b;
    return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
  });
};
