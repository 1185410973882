import { makeStyles } from '@material-ui/core/styles';
import {
  MediaQuery,
  Shadow,
  TextEllipsis,
  TextEllipsisLineClamp,
} from 'static/styles/jss/abstracts/mixins';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  tile__block: {
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer',
    backgroundColor: variables.$filters.$headerBG,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    marginBottom: '16px',
    position: 'relative',
    '& .promotion-icon': {
      zIndex: 2,
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: '0',
      display: 'flex',
    },
  },
  'details--block': {
    padding: '25px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...MediaQuery.down({
      padding: '10px 25px',
    }).xss,
    ...MediaQuery.down({
      paddingRight: '20px',
    }).lg,
    '& .sub-title-content': {
      fontSize: '15px !important',
      ...MediaQuery.down({
        fontSize: '13px !important',
      }).xs,
      ...MediaQuery.down({
        fontSize: '11px !important',
        marginBottom: '5px',
      }).xss,
    },
    '& .title-content': {
      marginBottom: '12px',
      ...MediaQuery.between({
        ...TextEllipsisLineClamp('18px !important', 2, 36, '18px'),
      }).xss_sm,
      ...MediaQuery.down({
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '13px',
        marginBottom: '5px',
      }).xss,
      ...MediaQuery.up({
        fontSize: '24px !important',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }).sm,
      width: '100%',
    },
    '& .bottom-info--block': {
      display: 'flex',
      alignItems: 'center',
      ...MediaQuery.down({
        flexDirection: 'column',
        alignItems: 'flex-start',
      }).sm,
      '& .sub-title-content': {
        margin: 0,
        color: variables.$greyTxtColor,
        '&:first-child': {
          ...TextEllipsis(),
          marginRight: '10px',
        },
        '&:last-child': {
          position: 'relative',
          paddingLeft: '10px',
          minWidth: '180px',
          ...MediaQuery.down({
            paddingLeft: '0px',
            '&::before': {
              display: 'none',
            },
          }).sm,
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '0',
            height: '3px',
            width: '3px',
            borderRadius: '50%',
            backgroundColor: '#C3CBD5',
            margin: 'auto',
            top: 0,
            bottom: 0,
          },
        },
      },
    },
  },
});
