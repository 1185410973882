import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'popular-categories-container': {
    minHeight: '270px',
    ...MediaQuery.up({
      backgroundColor: variables.$baseColorDark_30,
    }).sm,
    '& > span': {
      marginBottom: '16px',
      ...MediaQuery.down({
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        backgroundColor: variables.$baseColorDark_30,
        '& .bottom-data': {
          width: '60%',
          '& h4, & p': {
            textAlign: 'left',
            justifyContent: 'flex-start',
            whiteSpace: 'initial',
          },
        },
        '& .image-wrapper': {
          height: '110px',
          width: '40%',
        },
      }).sm,
    },
  },
});
