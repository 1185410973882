import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'information-wrapper': {
    padding: '25px 8px',
    '& .info-item': {
      '& h4': {
        fontWeight: 'bold',
        color: '#fff',
        fontSize: '18px',
        '& span': {
          marginRight: '10px',
          fontSize: '16px',
        },
      },
      '& p': {
        color: '#c3cbd5',
        fontSize: '14px',
        wordBreak: 'break-word',
        '& a': {
          color: '#fff',
          margin: '0 5px',
        },
      },
    },
  },
});
