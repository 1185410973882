import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'aside-menu-bar': {
    zIndex: 998,
    borderRadius: '3px',
    marginBottom: '15px',
    backgroundColor: variables.$lightBlue,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    position: 'relative',
    ...MediaQuery.between({
      minWidth: '305px',
      width: '40%',
    }).xss_sm,
    ...MediaQuery.down({
      borderRadius: '0px',
      backgroundColor: variables.$darkOpacity50,
      position: 'fixed',
      top: '0',
      zIndex: '999999',
      height: '100%',
      left: '0',
      transform: 'translateX(-100%)',
      transitionProperty: 'transform',
      transition: '.2s',
      padding: '10px',
      '&.open-mobile-categories_menu': {
        transform: 'translateX(0)',
      },
    }).sm,
    ...MediaQuery.down({
      width: '100%',
    }).xss,
    '& .login-creat-account_wrapper': {
      borderTop: '1px solid #3378D1',
      display: 'none',
      ...MediaQuery.down({
        display: 'block',
      }).sm,
      '& .action-button': {
        display: 'flex',
        alignItems: 'center',
        padding: '13px 10px',
        color: '#fff',
        fontSize: '13px',
        '& .icon-My-Account': {
          fontSize: '22px',
          marginRight: '15px',
        },
        '& .icon-Add-account': {
          fontSize: '22px',
          marginRight: '14px',
        },
      },
    },
  },
  'aside-menu-bar--child-fr': {
    ...MediaQuery.down({
      overflowX: 'hidden',
      maxHeight: '100%',
      '@media (hover: hover)': {
        paddingRight: '5px',
        '&::-webkit-scrollbar': {
          width: '0px',
          backgroundColor: '#07224D',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          background: variables.$helperTextColor,
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#C7C7C7 #07224D',
      },
      '& ul.parent-list--ul': {
        margin: '10px 0 !important',
      },
    }).sm,
    '@media (hover: none)': {
      '&::-webkit-scrollbar': {
        width: '0px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'transparent',
      },
    },
    '& .list-menu--ul': {
      padding: '0',
      '&:not(.sub-list_menu)': {
        '& > li': {
          '& > a': {
            paddingRight: '30px',
          },
        },
      },
      ...MediaQuery.down({
        '&:not(.sub-list_menu)': {
          position: 'relative',
          transition: '.4s',
          left: '0',
          '&.slide-to-left': {
            left: '-100%',
            '& > li': {
              '& .arrow_icon_list-item': {
                opacity: '0',
              },
            },
          },
        },
      }).sm,
    },
    '& .back-to-parent_category': {
      color: '#fff',
      display: 'block',
      padding: '13px 10px',
      fontWeight: '900',
      marginLeft: '-20px',
      fontSize: '14px',
      cursor: 'text',
    },
    '& .list-item--li': {
      fontSize: '13px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&.active-mobile-item': {
        '& .sub-list_menu': {
          opacity: 1,
          visibility: 'visible',
        },
      },
      ...MediaQuery.up({
        position: 'relative',
      }).sm,
      '&:not(.back-to-parent_category):hover': {
        backgroundColor: variables.$darkOpacity50,
        ...MediaQuery.up({
          '& .sub-list_menu': {
            opacity: '1',
            visibility: 'visible',
            zIndex: '222',
            display: 'flex',
          },
        }).sm,
      },
      '& .arrow_icon_list-item': {
        color: variables.$iconColor,
        position: 'absolute',
        fontSize: '12px',
        right: '10px',
        ...MediaQuery.down({
          right: '0px',
          padding: '10px',
        }).sm,
      },
    },
    '& .list-item--a': {
      fontFamily: '"Exo 2", sans-serif',
      flex: '1',
      display: 'block',
      padding: '13px 10px',
      color: '#fff !important',
      transition: '.1s ease-in-out',
      '&:hover': {
        color: '#fff',
        textShadow: '.5px 0px 0px #fff, 0px 0px 0px #fff, 0px 0px 0px #fff',
        letterSpacing: '0.2px',
      },
    },
    '& .mobile-top-panel': {
      borderBottom: '1px solid #3378D1',
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .ads-button': {
        // injected from header
        marginTop: '20px !important',
        padding: '8.5px 16px !important',
        width: '100% !important',
      },
      '& .mobile-top-panel--p': {
        paddingLeft: '40px',
        marginBottom: '0',
        lineHeight: '22px',
        position: 'relative',
        color: '#fff',
        fontSize: '13px',
        cursor: 'pointer',
        '& .icon-back-arrow': {
          left: '10px',
          fontSize: '18px',
          position: 'absolute',
          fontWeight: '900',
        },
      },
      '& .close-menu_icon': {
        color: '#fff',
        fontSize: '22px',
        cursor: 'pointer',
        marginLeft: '10px',
      },
    },
    '& .sub-list_menu': {
      position: 'absolute',
      left: '100%',
      width: '400px',
      ...Shadow('0 2px 8px 0 rgb(0 0 0 / 50%)'),
      opacity: '0',
      visibility: 'hidden',
      zIndex: '-1',
      display: 'flex',
      top: '0',
      ...MediaQuery.up({
        display: 'none',
        backgroundColor: variables.$darkOpacity50,
        flexWrap: 'wrap',
        '& > li': {
          width: '50%',
        },
      }).sm,
      ...MediaQuery.down({
        maxHeight: '100%',
        paddingLeft: '20px',
        flexDirection: 'column',
        overflowX: 'hidden',
        width: '100%',
        ...Shadow('none'),
        zIndex: '222',
        scrollbarColor: '#C7C7C7 #07224D',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '3px',
          backgroundColor: '#07224D',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          background: variables.$helperTextColor,
        },
      }).sm,
      '& li:first-child': {
        '&::after': {
          content: '""',
          position: 'absolute',
          left: '-10px',
          top: '0',
          height: '100%',
          width: '10px',
          zIndex: '2223',
          background: variables.$darkOpacity50,
        },
      },
    },
    '& ul.parent-list--ul': {
      margin: 0,
      '& li:hover .arrow_icon_list-item': {
        fontWeight: '900',
        color: '#fff',
      },
    },
  },
});
