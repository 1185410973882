import { memo } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import NextImage from 'next/image';
import useMemoSelector from 'hooks/useMemoSelector';

import { Image } from 'Components/Shared/UI';
import ImageSliderBadge from 'Components/Plugins/Sliders/ImageSliderBadge';

import { GLOBAL_CONSTANTS } from 'utils/constants';
import generateImgPath from 'utils/generateImgPath';

import { adCategories } from 'store/reselect';

import useStyles from './styles';

const AdTile = ({
  data: {
    city,
    title,
    photos,
    gender,
    tagline,
    title_slug: slug,
    count: itemsCount,
    promotion: adPromotion,
    category_id: categoryId,
    profile_name: profileName,
    profile_type: profileType,
  },
  type,
  isMobile,
  imgWrapper,
}) => {
  const t = useTranslations();
  const categories = useMemoSelector(adCategories);

  const genderIconName = profileType === 'company' ? 'business' : gender;

  const { value_nl } =
    categories.find(({ id }) => Number(id) === Number(categoryId)) || {};

  const styles = useStyles({
    imgWrapperH: imgWrapper?.h || 180,
  });
  const isSliderImage = Array.isArray(photos);

  const isPopularCategories = type === 'popular-category';

  const isAvailableNow = type === 'ad-tile';

  const { value: categoryName } = GLOBAL_CONSTANTS.categories.find(
    el => el.id === categoryId,
  );

  const generatedLink = isPopularCategories ? `/${value_nl}` : slug;

  const imageSize = isMobile ? '130' : '182';

  const categoryStaticImagePath =
    isPopularCategories &&
    `/images/popular-categories/${title}_${imageSize}.jpg`;

  return (
    <Link href={generatedLink || ''}>
      <a>
        <div
          style={{
            ...(isMobile && {
              display: 'flex',
              justifyContent: 'start',
              padding: '10px',
              backgroundColor: '#003271',
              marginBottom: '10px',
            }),
          }}
          className={`${styles['ad-tile-wrapper']} ${
            type === 'ad-tile'
              ? 'with-shadow'
              : type === 'popular-category'
              ? 'popular-categories-tile--block'
              : ''
          }`}
        >
          <div
            className="image-wrapper"
            style={{
              ...(isMobile && {
                marginRight: '20px',
                width: `${imageSize}px`,
                height: `${imageSize}px`,
              }),
            }}
          >
            {adPromotion && (
              <div className="promotion-icon">
                {adPromotion === 'gold' ? (
                  <img src="/images/icons/gold_icon.svg" alt="gold" />
                ) : adPromotion === 'silver' ? (
                  <img src="/images/icons/silver_icon.svg" alt="silver" />
                ) : (
                  ''
                )}
              </div>
            )}
            {isSliderImage ? (
              <ImageSliderBadge src={generateImgPath(photos, 'thumb_248')} />
            ) : categoryStaticImagePath ? (
              <NextImage
                alt="main ad"
                width={imageSize}
                height={imageSize}
                src={categoryStaticImagePath}
                onError={({ target }) => {
                  target.src = '/images/icons/upload.png';
                }}
              />
            ) : (
              <Image
                styles={{ width: '100%' }}
                src={generateImgPath(photos, 'thumb_248')}
                defaultSrc={'/images/icons/upload.png'}
              />
            )}
          </div>
          <div
            className={clsx('bottom-data', {
              'main-content': !isPopularCategories,
            })}
            style={{
              ...(!isMobile && {
                padding: '10px',
              }),
              ...(isMobile && {
                alignSelf: 'center',
              }),
            }}
          >
            {isPopularCategories ? (
              <>
                <h4
                  style={{
                    ...(isMobile && { fontWeight: 'bold', fontSize: '19px' }),
                  }}
                >
                  {t(title)}
                </h4>
                <p>
                  <span className="icon-Advertenties"></span>
                  {itemsCount}
                </p>
              </>
            ) : (
              <>
                <div className="title-info">
                  <h4>
                    <p
                      className="title-overflow"
                      style={{
                        WebkitLineClamp: tagline ? 1 : 2,
                      }}
                    >
                      {title ?? t('no_data_title')}
                    </p>
                    <span
                      className={`sticker__tile sticker__tile--${
                        adPromotion || 'silver'
                      }`}
                      style={{
                        margin: 0,
                        maxWidth: 'max-content',
                        background: !tagline && 'none',
                      }}
                    >
                      {tagline}
                    </span>
                  </h4>
                </div>
                <div className="location-info">
                  <p className="gender-type__block">
                    <img
                      src={`/images/icons/${
                        isAvailableNow ? genderIconName : gender?.type
                      }.svg`}
                      alt="gender-icon"
                    />
                    {profileName}
                  </p>

                  <p className="location-info-content">
                    <span className="icon-Advertenties"></span>
                    {t(categoryName)}
                  </p>
                  <p>
                    <span className="icon-Locatie"></span>
                    {city}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default memo(AdTile);
