import { MEDIA } from 'utils/constants';

const generateImgPath = (photos, thumb) => {
  if (photos?.length === 1) {
    const [{ path, hash, ext, v }] = photos;
    return [`${MEDIA.storagePath}/${path}/${hash}_${thumb}.${ext}?v=${v}`];
  } else {
    const imgPaths = [];
    photos?.forEach(({ path, hash, ext, v }) => {
      const newGeneratedPath = `${MEDIA.storagePath}/${path}/${hash}_${thumb}.${ext}?v=${v}`;
      imgPaths.push(newGeneratedPath);
    });
    return imgPaths;
  }
};

export default generateImgPath;
