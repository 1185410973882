import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'ad-tile-wrapper': {
    cursor: 'pointer',

    '& .gender-type__block': {
      paddingBottom: 5,
      borderBottom: '1px solid #094287',
      marginBottom: '7px',
      color: variables.$helperTextColor,
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      ...MediaQuery.down({
        marginBottom: '3px',
        fontSize: '11px',
      }).xss,
      '& img, & span': {
        maxWidth: '13px',
        marginRight: '10px',
      },
    },
    display: 'block',
    borderRadius: '3px',
    '&.with-shadow': {
      marginBottom: '10px',
      backgroundColor: variables.$lightBlue,
      boxShadow: '0 3px 6px 0 rgb(0 0 0 / 15%)',
      '& .image-wrapper .slider_badge_row': {
        '& > img': {
          borderRadius: '3px 3px 0 0',
        },
      },
    },
    '& .image-wrapper': {
      width: '100%',
      height: props => props.imgWrapperH,
      position: 'relative',
      '& > img': {
        height: '100%',
        objectFit: 'cover',
      },
      '& .promotion-icon': {
        zIndex: 2,
        position: 'absolute',
        width: '20px',
        height: '20px',
        top: '0',
        display: 'flex',
      },
    },
    '&.popular-categories-tile--block': {
      '& .bottom-data': {
        '& h4': {
          fontSize: '16px',
          color: '#fff',
          textAlign: 'center',
          margin: '0 0 7px 0',
          whiteSpace: 'nowrap',
        },
        '& p': {
          margin: '0',
          textAlign: 'center',
          fontSize: '13px',
          color: '#C3CBD5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& > span': {
            marginTop: '-2px',
            marginRight: '7px',
          },
        },
        ...MediaQuery.down({
          '& h4, & p': {
            textAlign: 'left',
            justifyContent: 'flex-start',
          },
        }).sm,
      },
    },
    '& .bottom-data': {
      '&.main-content': {
        display: 'flex',
        minHeight: 135,
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      paddingTop: '10px',
      '& .location-info': {
        '& .location-info-content': {
          marginTop: 5,
        },
        '& > p': {
          fontSize: '13px',
          marginBottom: '0px',
          height: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '&:last-child': {
            marginBottom: '0',
          },
          '& > span': {
            marginRight: '10px',
            marginLeft: '2px',
            color: variables.$greyTxtColor,
          },
          color: variables.$greyTxtColor,
        },
      },
    },
    '& .title-info': {
      minHeight: '70px',
      '& h4': {
        display: 'flex',
        marginBottom: '7px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#fff',
        '& p.title-overflow': {
          overflow: 'hidden',
          display: '-webkit-box',
          marginBottom: '7px',
          '-webkit-box-orient': 'vertical',
        },
      },
      '& > p': {
        marginBottom: '7px',
        color: variables.$greyTxtColor,
        fontSize: '13px',
        height: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});
